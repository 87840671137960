html {
  background-color: rgba(248, 248, 248, 1);
}

body {
  margin: 0;
  padding: 0;
  font-family: Be-;
}

.react-calendar__navigation__label {
  background: transparent;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  font-size: 17px;
  color: black;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.react-calendar__navigation__label:hover {
  cursor: pointer;
  background: #ebb04c;
  color: white;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next-button {
  background-color: transparent;
  border: none;
  margin-bottom: 1em;
  font-size: 17.59px;
}

.react-calendar__navigation__next-button:hover {
  cursor: pointer;
  background-color: #EEEEEE;
  background: #ebb04c;
  color: white;
}

.react-calendar__navigation__prev-button {
  background-color: transparent;
  border: none;
  margin-bottom: 1em;
  font-size: 17.59px;
}

.react-calendar__navigation__prev-button:hover {
  cursor: pointer;
  background-color: #EEEEEE;
  background: #ebb04c;
  color: white;
}


/* ADDED */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ebb04c;
  color: white;
  border-radius: '50%';
}

.react-calendar__tile--hasActive:enabled:hover {
  background-color: #ebb04c !important;
}

.react-calendar__tile--hasActive:enabled:focus {
  background-color: #ebb04c !important;
}

.react-calendar__tile--active {
  background: #ebb04c;
  border-radius: '50%';
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ebb04c;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background-color: #ebb04c !important;
  color: white;
  border-radius: 0;
}

/* The first tile in the range */
.react-calendar__tile--rangeStart {
  background-color: #ebb04c !important;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* The last tile in the range */
.react-calendar__tile--rangeEnd {
  background-color: #ebb04c !important;
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

a {
  color: 'rgba(218, 93, 62, 1)'
}

a:hover {
  color: 'rgba(235, 176, 76, 1)'
}